if(window.logging) console.log('loading qb_channel');

import { createConsumer } from "@rails/actioncable"

export class QBChannel {
    static get subscriptions() {
        if(QBChannel._subscriptions === undefined) QBChannel._subscriptions = {};
        return  QBChannel._subscriptions;
    }

    constructor(name, target, nickname) {
        this.nickname = nickname;
        this.targets = [];
        if(target) this.register(target);

        this.name = name;
        this.subscribe();
        QBChannel.subscriptions[name] = this;
    }
    
    get token() { return meta_content(this.name); }

    set_token(token) {
        if(this.token == token) return;
        set_meta(this.name, token);
        if(this.subscription) this.subscription.unsubscribe();
        this.subscribe();
    }

    register(target) { this.targets.push(target);}

    get connected() { return this._connected; }

    set connected(connected) {
        console.log(this.name, connected ? 'connected' : 'disconnected');
        if(this._connected !== undefined) {
            if(connected) QB.notice = __('%{channel} reconnected', { channel: this.nickname || this.name});
            else QB.alert = __('%{channel} disconnected', { channel: this.nickname || this.name});
        }
        this._connected = connected;
    }

    received(data) {
        if(window.logging) console.info(this.name, 'RCV', data);
        // try {
        const miss = [];
        const callback_name = `receive_${data.action}`;
        this.targets.forEach(target => {
            const callback = target[callback_name] || Object.getPrototypeOf(target)[callback_name];
            if (typeof callback != 'function') {
                miss.push(target);
                return;
            }
            data.me = QB.app_id == data.app_id;
            callback.call(target, data);
        });
        if (miss.length === this.targets.length) console.warn(this.name, 'RCV miss', miss, callback_name, data);
    }

    send(data) {
        data.app_id = QB.app_id;
        this.subscription.send(data)
    }

    subscribe() {
        const channel = this;
        this.subscription = createConsumer().subscriptions.create({
            channel: this.name, token: this.token
        }, {
            connected() { channel.connected = true },
            disconnected() { channel.connected = false },
            received(data) {
                // console.log('RCV', data);
                channel.received(data);
            }
        });
    }
}
